import React, { useEffect, useState } from 'react';

function ImageCropper(props) {
  const { img_path, location } = props;
  const [src, setSrc] = useState(img_path);
  const [crop, setCrop] = useState(location);
  const [loading, setLoading] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  useEffect(() => {
    if (img_path && location && location[0] != undefined && !loading) {
      setLoading(true);
      cropImage();
    }
  }, [img_path, location]);

  const cropImage = async () => {
    try {
      const croppedBase64 = await fetchCroppedImage(img_path, location);
      setCroppedImage(croppedBase64);
    } catch (error) {
      console.error('Error cropping image:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCroppedImage = async (base64Image, location) => {
    const [top, right, bottom, left] = location
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Image;
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const croppedWidth = right - left;
        const croppedHeight = bottom - top;

        const croppedCanvas = document.createElement('canvas');
        croppedCanvas.width = croppedWidth;
        croppedCanvas.height = croppedHeight;
        const croppedCtx = croppedCanvas.getContext('2d');

        croppedCtx.drawImage(
          img,
          left,
          top,
          croppedWidth,
          croppedHeight,
          0,
          0,
          croppedWidth,
          croppedHeight
        );

        resolve(croppedCanvas.toDataURL('image/jpeg'));
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        croppedImage && <img src={croppedImage} alt="Cropped" style={{ width: '100px', maxWidth: '100px' }} />
      )}
    </div>
  );
}

export default ImageCropper;