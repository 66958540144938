import axios from "axios";
import { apiHeaderMultipart, apiHeaderX } from "../dataServices";
import { API } from "../../../config";

export const demoAddImage = async ( image, cam, datetime, token)=> {
    const config = {
        headers: apiHeaderMultipart(token)
    };

    const formData = new FormData();
    formData.append('uploadFile', image);
    formData.append('cam', cam);
    formData.append('datetime', datetime);

    return await axios.post(`${API}/api/car_parking/face/demo/addImage`,
        formData,
        config
    )
}

export const demoSearchImage = async (image, token) => {
    const config = {
        headers: apiHeaderMultipart(token)
    };

    const formData = new FormData();
    formData.append('uploadFile', image);

    return await axios.post(`${API}/api/car_parking/face/demo/searchImage`,
        formData,
        config
    )
}

export const demoFaceLogin = async ( username, password)=> {
    const config ={
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }
    const loginInfo = {"username": username, "password": password}
    return await axios.post(`${API}/api/car_parking/mobile/auth/login`,
        loginInfo,
        config
    )
}
