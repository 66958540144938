import './App.css';
import React, { useState, useEffect } from 'react';
import DemoFaceModule from './components/pages/face_detection/DemoFaceModule';

const App = () => {

  const renderApp = () => {
    let id = null;
    window.Buffer = window.Buffer || require("buffer-from").Buffer;
    return <DemoFaceModule />
  }

  return (
    <div>
      {renderApp()}
    </div>
  )
};

export default App;
